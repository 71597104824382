/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { ScreenConfiguration } from "./screen-configuration";
import { SearchParams } from "./search-params";

export const protobufPackage = "";

/** requires(NONE) */
export interface ScreenConfigurationListReq {
  searchParams:
    | SearchParams
    | undefined;
  /** If this is set, only return configurations that are children of this workspace */
  snapshotParentId: string;
}

export interface ScreenConfigurationListResp {
  screenConfigurations: ScreenConfiguration[];
}

/** requires(NONE) */
export interface ScreenConfigurationGetReq {
  id: string;
  scanId: string;
}

export interface ScreenConfigurationGetResp {
  screenConfiguration: ScreenConfiguration | undefined;
}

/** requires(EDIT_VIEW_STATE) */
export interface ScreenConfigurationWriteReq {
  scanId: string;
  screenConfiguration: ScreenConfiguration | undefined;
}

export interface ScreenConfigurationWriteResp {
  screenConfiguration: ScreenConfiguration | undefined;
}

/** requires(EDIT_VIEW_STATE) */
export interface ScreenConfigurationDeleteReq {
  id: string;
}

export interface ScreenConfigurationDeleteResp {
  id: string;
}

function createBaseScreenConfigurationListReq(): ScreenConfigurationListReq {
  return { searchParams: undefined, snapshotParentId: "" };
}

export const ScreenConfigurationListReq = {
  encode(message: ScreenConfigurationListReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchParams !== undefined) {
      SearchParams.encode(message.searchParams, writer.uint32(10).fork()).ldelim();
    }
    if (message.snapshotParentId !== "") {
      writer.uint32(18).string(message.snapshotParentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationListReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationListReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchParams = SearchParams.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.snapshotParentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationListReq {
    return {
      searchParams: isSet(object.searchParams) ? SearchParams.fromJSON(object.searchParams) : undefined,
      snapshotParentId: isSet(object.snapshotParentId) ? String(object.snapshotParentId) : "",
    };
  },

  toJSON(message: ScreenConfigurationListReq): unknown {
    const obj: any = {};
    message.searchParams !== undefined &&
      (obj.searchParams = message.searchParams ? SearchParams.toJSON(message.searchParams) : undefined);
    message.snapshotParentId !== undefined && (obj.snapshotParentId = message.snapshotParentId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationListReq>, I>>(base?: I): ScreenConfigurationListReq {
    return ScreenConfigurationListReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationListReq>, I>>(object: I): ScreenConfigurationListReq {
    const message = createBaseScreenConfigurationListReq();
    message.searchParams = (object.searchParams !== undefined && object.searchParams !== null)
      ? SearchParams.fromPartial(object.searchParams)
      : undefined;
    message.snapshotParentId = object.snapshotParentId ?? "";
    return message;
  },
};

function createBaseScreenConfigurationListResp(): ScreenConfigurationListResp {
  return { screenConfigurations: [] };
}

export const ScreenConfigurationListResp = {
  encode(message: ScreenConfigurationListResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.screenConfigurations) {
      ScreenConfiguration.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationListResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationListResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.screenConfigurations.push(ScreenConfiguration.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationListResp {
    return {
      screenConfigurations: Array.isArray(object?.screenConfigurations)
        ? object.screenConfigurations.map((e: any) => ScreenConfiguration.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScreenConfigurationListResp): unknown {
    const obj: any = {};
    if (message.screenConfigurations) {
      obj.screenConfigurations = message.screenConfigurations.map((e) => e ? ScreenConfiguration.toJSON(e) : undefined);
    } else {
      obj.screenConfigurations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationListResp>, I>>(base?: I): ScreenConfigurationListResp {
    return ScreenConfigurationListResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationListResp>, I>>(object: I): ScreenConfigurationListResp {
    const message = createBaseScreenConfigurationListResp();
    message.screenConfigurations = object.screenConfigurations?.map((e) => ScreenConfiguration.fromPartial(e)) || [];
    return message;
  },
};

function createBaseScreenConfigurationGetReq(): ScreenConfigurationGetReq {
  return { id: "", scanId: "" };
}

export const ScreenConfigurationGetReq = {
  encode(message: ScreenConfigurationGetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationGetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationGetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationGetReq {
    return { id: isSet(object.id) ? String(object.id) : "", scanId: isSet(object.scanId) ? String(object.scanId) : "" };
  },

  toJSON(message: ScreenConfigurationGetReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationGetReq>, I>>(base?: I): ScreenConfigurationGetReq {
    return ScreenConfigurationGetReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationGetReq>, I>>(object: I): ScreenConfigurationGetReq {
    const message = createBaseScreenConfigurationGetReq();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    return message;
  },
};

function createBaseScreenConfigurationGetResp(): ScreenConfigurationGetResp {
  return { screenConfiguration: undefined };
}

export const ScreenConfigurationGetResp = {
  encode(message: ScreenConfigurationGetResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.screenConfiguration !== undefined) {
      ScreenConfiguration.encode(message.screenConfiguration, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationGetResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationGetResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.screenConfiguration = ScreenConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationGetResp {
    return {
      screenConfiguration: isSet(object.screenConfiguration)
        ? ScreenConfiguration.fromJSON(object.screenConfiguration)
        : undefined,
    };
  },

  toJSON(message: ScreenConfigurationGetResp): unknown {
    const obj: any = {};
    message.screenConfiguration !== undefined && (obj.screenConfiguration = message.screenConfiguration
      ? ScreenConfiguration.toJSON(message.screenConfiguration)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationGetResp>, I>>(base?: I): ScreenConfigurationGetResp {
    return ScreenConfigurationGetResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationGetResp>, I>>(object: I): ScreenConfigurationGetResp {
    const message = createBaseScreenConfigurationGetResp();
    message.screenConfiguration = (object.screenConfiguration !== undefined && object.screenConfiguration !== null)
      ? ScreenConfiguration.fromPartial(object.screenConfiguration)
      : undefined;
    return message;
  },
};

function createBaseScreenConfigurationWriteReq(): ScreenConfigurationWriteReq {
  return { scanId: "", screenConfiguration: undefined };
}

export const ScreenConfigurationWriteReq = {
  encode(message: ScreenConfigurationWriteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.screenConfiguration !== undefined) {
      ScreenConfiguration.encode(message.screenConfiguration, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationWriteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationWriteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.screenConfiguration = ScreenConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationWriteReq {
    return {
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      screenConfiguration: isSet(object.screenConfiguration)
        ? ScreenConfiguration.fromJSON(object.screenConfiguration)
        : undefined,
    };
  },

  toJSON(message: ScreenConfigurationWriteReq): unknown {
    const obj: any = {};
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.screenConfiguration !== undefined && (obj.screenConfiguration = message.screenConfiguration
      ? ScreenConfiguration.toJSON(message.screenConfiguration)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationWriteReq>, I>>(base?: I): ScreenConfigurationWriteReq {
    return ScreenConfigurationWriteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationWriteReq>, I>>(object: I): ScreenConfigurationWriteReq {
    const message = createBaseScreenConfigurationWriteReq();
    message.scanId = object.scanId ?? "";
    message.screenConfiguration = (object.screenConfiguration !== undefined && object.screenConfiguration !== null)
      ? ScreenConfiguration.fromPartial(object.screenConfiguration)
      : undefined;
    return message;
  },
};

function createBaseScreenConfigurationWriteResp(): ScreenConfigurationWriteResp {
  return { screenConfiguration: undefined };
}

export const ScreenConfigurationWriteResp = {
  encode(message: ScreenConfigurationWriteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.screenConfiguration !== undefined) {
      ScreenConfiguration.encode(message.screenConfiguration, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationWriteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationWriteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.screenConfiguration = ScreenConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationWriteResp {
    return {
      screenConfiguration: isSet(object.screenConfiguration)
        ? ScreenConfiguration.fromJSON(object.screenConfiguration)
        : undefined,
    };
  },

  toJSON(message: ScreenConfigurationWriteResp): unknown {
    const obj: any = {};
    message.screenConfiguration !== undefined && (obj.screenConfiguration = message.screenConfiguration
      ? ScreenConfiguration.toJSON(message.screenConfiguration)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationWriteResp>, I>>(base?: I): ScreenConfigurationWriteResp {
    return ScreenConfigurationWriteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationWriteResp>, I>>(object: I): ScreenConfigurationWriteResp {
    const message = createBaseScreenConfigurationWriteResp();
    message.screenConfiguration = (object.screenConfiguration !== undefined && object.screenConfiguration !== null)
      ? ScreenConfiguration.fromPartial(object.screenConfiguration)
      : undefined;
    return message;
  },
};

function createBaseScreenConfigurationDeleteReq(): ScreenConfigurationDeleteReq {
  return { id: "" };
}

export const ScreenConfigurationDeleteReq = {
  encode(message: ScreenConfigurationDeleteReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationDeleteReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationDeleteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationDeleteReq {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ScreenConfigurationDeleteReq): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationDeleteReq>, I>>(base?: I): ScreenConfigurationDeleteReq {
    return ScreenConfigurationDeleteReq.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationDeleteReq>, I>>(object: I): ScreenConfigurationDeleteReq {
    const message = createBaseScreenConfigurationDeleteReq();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseScreenConfigurationDeleteResp(): ScreenConfigurationDeleteResp {
  return { id: "" };
}

export const ScreenConfigurationDeleteResp = {
  encode(message: ScreenConfigurationDeleteResp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScreenConfigurationDeleteResp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScreenConfigurationDeleteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScreenConfigurationDeleteResp {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ScreenConfigurationDeleteResp): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScreenConfigurationDeleteResp>, I>>(base?: I): ScreenConfigurationDeleteResp {
    return ScreenConfigurationDeleteResp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScreenConfigurationDeleteResp>, I>>(
    object: I,
  ): ScreenConfigurationDeleteResp {
    const message = createBaseScreenConfigurationDeleteResp();
    message.id = object.id ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
