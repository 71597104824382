"use strict";

var protobuf = exports;

/**
 * Build type, one of `"full"`, `"light"` or `"minimal"`.
 * @name build
 * @type {string}
 * @const
 */
protobuf.build = "minimal";

// Serialization
protobuf.Writer = require("./writer");
protobuf.BufferWriter = require("./writer_buffer");
protobuf.Reader = require("./reader");
protobuf.BufferReader = require("./reader_buffer");

// Utility
protobuf.util = require("./util/minimal");
protobuf.rpc = require("./rpc");
protobuf.roots = require("./roots");
protobuf.configure = configure;

/* istanbul ignore next */
/**
 * Reconfigures the library according to the environment.
 * @returns {undefined}
 */
function configure() {
  protobuf.util._configure();
  protobuf.Writer._configure(protobuf.BufferWriter);
  protobuf.Reader._configure(protobuf.BufferReader);
}

// Set up buffer utility according to the environment
configure();