/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { OwnershipSummary } from "./ownership-access";

export const protobufPackage = "";

export interface MistROIItem {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  scanId: string;
  species: string;
  mineralGroupID: string;
  idDepth: number;
  classificationTrail: string;
  formula: string;
}

export interface ROIItemDisplaySettings {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  shape: string;
  colour: string;
}

export interface ROIItem {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  scanId: string;
  name: string;
  description: string;
  scanEntryIndexesEncoded: number[];
  imageName: string;
  pixelIndexesEncoded: number[];
  /** Only sent out by API, not stored in DB this way */
  mistROIItem: MistROIItem | undefined;
  isMIST: boolean;
  tags: string[];
  modifiedUnixSec: number;
  displaySettings:
    | ROIItemDisplaySettings
    | undefined;
  /** Only sent out by API, not stored in DB this way */
  owner: OwnershipSummary | undefined;
}

export interface ROIItemSummary {
  /** @gotags: bson:"_id,omitempty" */
  id: string;
  scanId: string;
  name: string;
  description: string;
  imageName: string;
  tags: string[];
  modifiedUnixSec: number;
  /** Only sent out by API, not stored in DB this way */
  mistROIItem: MistROIItem | undefined;
  isMIST: boolean;
  displaySettings:
    | ROIItemDisplaySettings
    | undefined;
  /** Only sent out by API, not stored in DB this way */
  owner: OwnershipSummary | undefined;
}

function createBaseMistROIItem(): MistROIItem {
  return { id: "", scanId: "", species: "", mineralGroupID: "", idDepth: 0, classificationTrail: "", formula: "" };
}

export const MistROIItem = {
  encode(message: MistROIItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.species !== "") {
      writer.uint32(26).string(message.species);
    }
    if (message.mineralGroupID !== "") {
      writer.uint32(34).string(message.mineralGroupID);
    }
    if (message.idDepth !== 0) {
      writer.uint32(40).int32(message.idDepth);
    }
    if (message.classificationTrail !== "") {
      writer.uint32(50).string(message.classificationTrail);
    }
    if (message.formula !== "") {
      writer.uint32(58).string(message.formula);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MistROIItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMistROIItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.species = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mineralGroupID = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.idDepth = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.classificationTrail = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.formula = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MistROIItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      species: isSet(object.species) ? String(object.species) : "",
      mineralGroupID: isSet(object.mineralGroupID) ? String(object.mineralGroupID) : "",
      idDepth: isSet(object.idDepth) ? Number(object.idDepth) : 0,
      classificationTrail: isSet(object.classificationTrail) ? String(object.classificationTrail) : "",
      formula: isSet(object.formula) ? String(object.formula) : "",
    };
  },

  toJSON(message: MistROIItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.species !== undefined && (obj.species = message.species);
    message.mineralGroupID !== undefined && (obj.mineralGroupID = message.mineralGroupID);
    message.idDepth !== undefined && (obj.idDepth = Math.round(message.idDepth));
    message.classificationTrail !== undefined && (obj.classificationTrail = message.classificationTrail);
    message.formula !== undefined && (obj.formula = message.formula);
    return obj;
  },

  create<I extends Exact<DeepPartial<MistROIItem>, I>>(base?: I): MistROIItem {
    return MistROIItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MistROIItem>, I>>(object: I): MistROIItem {
    const message = createBaseMistROIItem();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.species = object.species ?? "";
    message.mineralGroupID = object.mineralGroupID ?? "";
    message.idDepth = object.idDepth ?? 0;
    message.classificationTrail = object.classificationTrail ?? "";
    message.formula = object.formula ?? "";
    return message;
  },
};

function createBaseROIItemDisplaySettings(): ROIItemDisplaySettings {
  return { id: "", shape: "", colour: "" };
}

export const ROIItemDisplaySettings = {
  encode(message: ROIItemDisplaySettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.shape !== "") {
      writer.uint32(18).string(message.shape);
    }
    if (message.colour !== "") {
      writer.uint32(26).string(message.colour);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIItemDisplaySettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIItemDisplaySettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.shape = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.colour = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIItemDisplaySettings {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      shape: isSet(object.shape) ? String(object.shape) : "",
      colour: isSet(object.colour) ? String(object.colour) : "",
    };
  },

  toJSON(message: ROIItemDisplaySettings): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.shape !== undefined && (obj.shape = message.shape);
    message.colour !== undefined && (obj.colour = message.colour);
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIItemDisplaySettings>, I>>(base?: I): ROIItemDisplaySettings {
    return ROIItemDisplaySettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIItemDisplaySettings>, I>>(object: I): ROIItemDisplaySettings {
    const message = createBaseROIItemDisplaySettings();
    message.id = object.id ?? "";
    message.shape = object.shape ?? "";
    message.colour = object.colour ?? "";
    return message;
  },
};

function createBaseROIItem(): ROIItem {
  return {
    id: "",
    scanId: "",
    name: "",
    description: "",
    scanEntryIndexesEncoded: [],
    imageName: "",
    pixelIndexesEncoded: [],
    mistROIItem: undefined,
    isMIST: false,
    tags: [],
    modifiedUnixSec: 0,
    displaySettings: undefined,
    owner: undefined,
  };
}

export const ROIItem = {
  encode(message: ROIItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    writer.uint32(42).fork();
    for (const v of message.scanEntryIndexesEncoded) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.imageName !== "") {
      writer.uint32(50).string(message.imageName);
    }
    writer.uint32(58).fork();
    for (const v of message.pixelIndexesEncoded) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.mistROIItem !== undefined) {
      MistROIItem.encode(message.mistROIItem, writer.uint32(66).fork()).ldelim();
    }
    if (message.isMIST === true) {
      writer.uint32(72).bool(message.isMIST);
    }
    for (const v of message.tags) {
      writer.uint32(82).string(v!);
    }
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(88).uint32(message.modifiedUnixSec);
    }
    if (message.displaySettings !== undefined) {
      ROIItemDisplaySettings.encode(message.displaySettings, writer.uint32(98).fork()).ldelim();
    }
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag === 40) {
            message.scanEntryIndexesEncoded.push(reader.int32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.scanEntryIndexesEncoded.push(reader.int32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 7:
          if (tag === 56) {
            message.pixelIndexesEncoded.push(reader.int32());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pixelIndexesEncoded.push(reader.int32());
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.mistROIItem = MistROIItem.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.displaySettings = ROIItemDisplaySettings.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      scanEntryIndexesEncoded: Array.isArray(object?.scanEntryIndexesEncoded)
        ? object.scanEntryIndexesEncoded.map((e: any) => Number(e))
        : [],
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      pixelIndexesEncoded: Array.isArray(object?.pixelIndexesEncoded)
        ? object.pixelIndexesEncoded.map((e: any) => Number(e))
        : [],
      mistROIItem: isSet(object.mistROIItem) ? MistROIItem.fromJSON(object.mistROIItem) : undefined,
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      displaySettings: isSet(object.displaySettings)
        ? ROIItemDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: ROIItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    if (message.scanEntryIndexesEncoded) {
      obj.scanEntryIndexesEncoded = message.scanEntryIndexesEncoded.map((e) => Math.round(e));
    } else {
      obj.scanEntryIndexesEncoded = [];
    }
    message.imageName !== undefined && (obj.imageName = message.imageName);
    if (message.pixelIndexesEncoded) {
      obj.pixelIndexesEncoded = message.pixelIndexesEncoded.map((e) => Math.round(e));
    } else {
      obj.pixelIndexesEncoded = [];
    }
    message.mistROIItem !== undefined &&
      (obj.mistROIItem = message.mistROIItem ? MistROIItem.toJSON(message.mistROIItem) : undefined);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ROIItemDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIItem>, I>>(base?: I): ROIItem {
    return ROIItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIItem>, I>>(object: I): ROIItem {
    const message = createBaseROIItem();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.scanEntryIndexesEncoded = object.scanEntryIndexesEncoded?.map((e) => e) || [];
    message.imageName = object.imageName ?? "";
    message.pixelIndexesEncoded = object.pixelIndexesEncoded?.map((e) => e) || [];
    message.mistROIItem = (object.mistROIItem !== undefined && object.mistROIItem !== null)
      ? MistROIItem.fromPartial(object.mistROIItem)
      : undefined;
    message.isMIST = object.isMIST ?? false;
    message.tags = object.tags?.map((e) => e) || [];
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ROIItemDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

function createBaseROIItemSummary(): ROIItemSummary {
  return {
    id: "",
    scanId: "",
    name: "",
    description: "",
    imageName: "",
    tags: [],
    modifiedUnixSec: 0,
    mistROIItem: undefined,
    isMIST: false,
    displaySettings: undefined,
    owner: undefined,
  };
}

export const ROIItemSummary = {
  encode(message: ROIItemSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scanId !== "") {
      writer.uint32(18).string(message.scanId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.imageName !== "") {
      writer.uint32(42).string(message.imageName);
    }
    for (const v of message.tags) {
      writer.uint32(50).string(v!);
    }
    if (message.modifiedUnixSec !== 0) {
      writer.uint32(56).uint32(message.modifiedUnixSec);
    }
    if (message.mistROIItem !== undefined) {
      MistROIItem.encode(message.mistROIItem, writer.uint32(66).fork()).ldelim();
    }
    if (message.isMIST === true) {
      writer.uint32(72).bool(message.isMIST);
    }
    if (message.displaySettings !== undefined) {
      ROIItemDisplaySettings.encode(message.displaySettings, writer.uint32(82).fork()).ldelim();
    }
    if (message.owner !== undefined) {
      OwnershipSummary.encode(message.owner, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ROIItemSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseROIItemSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scanId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.modifiedUnixSec = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.mistROIItem = MistROIItem.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isMIST = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.displaySettings = ROIItemDisplaySettings.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.owner = OwnershipSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ROIItemSummary {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      scanId: isSet(object.scanId) ? String(object.scanId) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      imageName: isSet(object.imageName) ? String(object.imageName) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      modifiedUnixSec: isSet(object.modifiedUnixSec) ? Number(object.modifiedUnixSec) : 0,
      mistROIItem: isSet(object.mistROIItem) ? MistROIItem.fromJSON(object.mistROIItem) : undefined,
      isMIST: isSet(object.isMIST) ? Boolean(object.isMIST) : false,
      displaySettings: isSet(object.displaySettings)
        ? ROIItemDisplaySettings.fromJSON(object.displaySettings)
        : undefined,
      owner: isSet(object.owner) ? OwnershipSummary.fromJSON(object.owner) : undefined,
    };
  },

  toJSON(message: ROIItemSummary): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.scanId !== undefined && (obj.scanId = message.scanId);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.imageName !== undefined && (obj.imageName = message.imageName);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.modifiedUnixSec !== undefined && (obj.modifiedUnixSec = Math.round(message.modifiedUnixSec));
    message.mistROIItem !== undefined &&
      (obj.mistROIItem = message.mistROIItem ? MistROIItem.toJSON(message.mistROIItem) : undefined);
    message.isMIST !== undefined && (obj.isMIST = message.isMIST);
    message.displaySettings !== undefined && (obj.displaySettings = message.displaySettings
      ? ROIItemDisplaySettings.toJSON(message.displaySettings)
      : undefined);
    message.owner !== undefined && (obj.owner = message.owner ? OwnershipSummary.toJSON(message.owner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ROIItemSummary>, I>>(base?: I): ROIItemSummary {
    return ROIItemSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ROIItemSummary>, I>>(object: I): ROIItemSummary {
    const message = createBaseROIItemSummary();
    message.id = object.id ?? "";
    message.scanId = object.scanId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.imageName = object.imageName ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.modifiedUnixSec = object.modifiedUnixSec ?? 0;
    message.mistROIItem = (object.mistROIItem !== undefined && object.mistROIItem !== null)
      ? MistROIItem.fromPartial(object.mistROIItem)
      : undefined;
    message.isMIST = object.isMIST ?? false;
    message.displaySettings = (object.displaySettings !== undefined && object.displaySettings !== null)
      ? ROIItemDisplaySettings.fromPartial(object.displaySettings)
      : undefined;
    message.owner = (object.owner !== undefined && object.owner !== null)
      ? OwnershipSummary.fromPartial(object.owner)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
